<div class="terms-conditions" *ngIf="language==1 || language=='1'">
    <div class="smallcontainer container">
        <div class="termsWrap">
            <h1>TÉRMINOS Y CONDICIONES</h1>
            <h5> Aspectos Generales</h5>
            <p>
                Buki Peluplanner S.A.C. identificada con R.U.C. N° 20610927379, con domicilio en Av. Javier Prado Este N° 2248, distrito de San Borja, provincia y departamento de Lima, es una empresa constituida bajo las leyes de la Republica del Perú titular de esta plataforma digital a quien, para todos los efectos de los presentes términos y condiciones, en adelante, se le denominará “Buki”. 
            </p>

            <h5> Definiciones </h5>
            <p>
                En la interpretación y aplicación de los presentes términos y condiciones se considerará las siguientes definiciones:

            </p>

            <ol class="dot">
                <li><b>Establecimiento comercial:</b>centro de belleza afiliado a la plataforma Buki para la oferta de servicios de belleza, estéticos y spa a los Usuarios.
                </li>

                <li>
                    <b>Datos de acceso:</b>nombre de usuario y contraseña empleados para el registro y el inicio de sesión en la cuenta creada por el Usuario en plataforma Buki.
                </li>

                <li>
                    <b>Formularios virtuales:</b>formularios en formato digital puestos a disposición en la plataforma para el registro mediante el llenado de la información personal exigida en ellos
                </li>

                <li><b>Freelancer: profesional de la belleza o estilista afiliado a la plataforma Buki que presta servicios de belleza y/o estéticos de manera independiente a los Usuarios.</b>
                </li>

                <li><b>Registro:</b>proceso mediante el cual una persona natural ingresa su información personal al formulario virtual con la finalidad de crear una cuenta en la plataforma Buki. </li>

                <li><b>Servicio:</b>labor de intermediación realizada por la plataforma digital para facilitar la relación de consumo entre los Usuarios y los centros de belleza afiliados a la plataforma.</li>
                <li><b>Orden de servicio:</b>solicitud requerida por el Usuario respecto de un servicio provisto por un centro de belleza o Freelancer luego de haber sido confirmado el pago de la misma.</li>
                <li><b>Usuario:</b>persona natural que tiene registrada una cuenta en la plataforma Buki.</li>
            </ol>

            <h5>Funcionamiento de la plataforma</h5>

            <p>La plataforma Buki es una herramienta tecnológica que busca facilitar la intermediación entre los Usuarios y los Establecimientos Comerciales o Freelancers con la finalidad de que los primeros puedan contratar los servicios ofrecidos por estos últimos. En atención a ello, el usuario reconoce que en todo momento Buki actúa como un tercero intermediario ajeno a las relaciones de consumo celebradas entre los Usuarios y los Establecimientos Comerciales o Freelancers. </p>
            <h5>Aceptación de los términos y condiciones</h5>
            <p>Los presentes términos y condiciones rigen el servicio de intermediación brindado a través de la plataforma Buki no existiendo fuentes alternativas que puedan ser alegadas por los Usuarios salvo aquellas obligaciones a las que se encuentre sujeto Buki por mandato legal. </p>
            <p>El Usuario al momento de registrarse en la plataforma Buki de manera voluntaria y expresa acepta el contenido de los presentes términos y condiciones obligándose a su pleno cumplimiento de forma irrevocable. El Usuario declara haber leído y comprendido todas las condiciones y obligaciones contenidas en los presente términos y condiciones manifestando su conformidad con su solo registro y/o uso de la plataforma.</p>
            <p>La persona natural que acepta los presentes términos y condiciones declara y garantiza que es el titular de la información personal que consigna durante el registro como Usuario en la plataforma Buki. Asimismo, declara que la información personal proporcionada para su registro es verdadera y que asume responsabilidad por el suministro de información inexacta, errónea o fraudulenta.  </p>
        
            <h5>Registro del Usuario:</h5>
            <p>El registro a la plataforma Buki es gratuito y deberá ser realizado de forma personal por el Usuario de la cuenta. El Usuario únicamente podrá acceder a la plataforma Buki a través de los medios debidamente autorizados. </p>
            <p>El Usuario deberá registrarse de manera obligatoria en la plataforma Buki para poder acceder a los contenidos provistos por esta. Para ello, deberá contar con un teléfono inteligente (smartphone) con sistemas operativos iOS o Android y conexión a internet para poder realizar la descarga del aplicativo en su dispositivo. Aquellas personas que no se hayan registrado como Usuarios de la plataforma podrán visualizar el contenido de la misma pero no podrán disponer de los servicios ofrecidos por los Establecimientos Comerciales o Freelancers sin previo registro.</p>
            <p>Asimismo, el Usuario deberá llenar los formularios virtuales de inscripción con su información personal y verificar que cada uno de los datos proporcionados para el registro sean correctos. Además, el Usuario asume el compromiso de actualizar los datos personales provistos originalmente para el registro cuando estos hayan sufrido cambios o modificaciones. </p>
            <p>Una vez realizado el registro se asignará al Usuario una cuenta personal para acceder al contenido de la plataforma Buki y poder agendar las citas con los distintos Establecimientos Comerciales o Freelancers que ofertan en dicho espacio. La cuenta creada por el Usuario es de naturaleza personal, única e intransferible por lo que se encuentra prohibido que un mismo Usuario registre más de una cuenta.</p>
            <p>El ingreso a la plataforma Buki se realizará mediante el ingreso de los datos de acceso generados para estos efectos compuestos por el usuario y la contraseña, que permitan validar la identidad del Usuario registrado</p>
            <p>Buki podrá emplear mecanismos de seguridad adicionales como preguntas personales, validación vía correo electrónico, envío de códigos de seguridad a dispositivos móviles, entre otros, con la finalidad de comprobar la identidad del Usuario. Sin embargo, Buki no se responsabiliza en caso de que el Usuario brinde sus datos de acceso a terceros para ingresar a la plataforma Buki y/o hacer uso de la misma.</p>
            <p>Buki se reserva el derecho de suspender, cancelar o inhabilitar, temporal o definitivamente, una cuenta registrada por un Usuario en la plataforma en los siguientes casos:
            </p>
            <ol class="dot">
                <li>Cuando los datos personales proporcionados para el registro de una cuenta no son veraces o exactos.
                </li>
                <li>Cuando exista duplicidad de cuentas por parte de un mismo Usuario o coincidencias en la información personal correspondiente a más de una cuenta registrada en la plataforma.</li>
                <li>Cuando existan indicios de fraude o de suplantación de identidad respecto a una cuenta registrada.</li>
                <li>Cuando el Usuario incumpla cualquiera de los términos y condiciones de uso de la plataforma.</li>
                <li>Cuando el Usuario solicita dar de baja a su cuenta.</li>
                <li>Cuando exista mandato de autoridad administrativa o judicial que lo solicite.</li>
            </ol>
            <h5>Uso de la plataforma Buki:</h5>
            <p>El Usuario será responsable por la compatibilidad de su teléfono inteligente (smartphone) con el uso del aplicativo que contiene la plataforma Buki. En ese sentido, Buki no garantiza el correcto funcionamiento del aplicativo en caso de que el dispositivo móvil del Usuario no reúna las condiciones técnicas y de conexión a internet que permitan su adecuada instalación y funcionamiento.</p>
            <p>El Usuario se compromete a hacer uso adecuado y lícito de la plataforma Buki de conformidad con la legislación aplicable vigente, los presentes términos y condiciones, la moral y las buenas costumbres. En general, el Usuario se compromete a actuar con buena fe en el uso de la plataforma Buki, para cumplir con los presentes términos y condiciones y con las obligaciones que asumen en virtud de su aceptación.</p>
            <p>El Usuario tiene conocimiento de que la plataforma Buki podría presentar limitaciones de disponibilidad y continuidad en su funcionamiento debido a circunstancias de mantenimiento o por causas ajenas que escapen del control de Buki. En estos casos, Buki adoptará todas las medidas que razonablemente se encuentren dentro de su alcance y control para restituir el correcto funcionamiento de la plataforma digital.</p>
            <p>Sin perjuicio de ello, el Usuario reconoce que Buki no tendrá responsabilidad en aquellos supuestos de ruptura del nexo causal previstos en la regulación y el ordenamiento legal vigente.  </p>
            <h5>Obligaciones del Usuario:</h5>
            <p>Por medio de la aceptación de los presentes términos y condiciones el Usuario se compromete de manera voluntaria, expresa e irrevocable a cumplir con las siguientes obligaciones:</p>
            <ol class="decimal">
                <li>No transferirá, revenderá o cederá su cuenta a terceros. Tampoco otorga autorización a terceros para que hagan uso de la misma.</li>
                <li>No podrá hacer uso de una cuenta distinta a la suya sin la debida autorización de su titular.</li>
                <li>No podrá emplear la plataforma con fines ilícitos o ilegales que pueda implicar la vulneración de derechos de terceros.</li>
                <li>Guardará la confidencialidad de sus datos de acceso a la plataforma Buki como son su nombre de usuario y su contraseña encontrándose prohibido de transmitirlos o cederlos a terceros</li>
                <li>Mantendrá actualizados los datos personales registrados en su cuenta cuando estos sean modificados.</li>
                <li>No utilizará la plataforma de Buki con un dispositivo móvil incompatible o no autorizado.</li>
                <li>No podrá acceder o utilizar los datos personales de otros Usuarios para fines no previstos en los términos y condiciones</li>
                <li>No manipulará ni sobeteará la plataforma digital a través de la introducción de virus, softwares, programas o mediante cualquier método tecnológico o análogo con fines ilícitos o fraudulentos que le puedan ocasionar un daño y/o afectar su normal funcionamiento.
                </li>
            </ol>
            <h5>Condiciones del Servicio:</h5>
            <p>Mediante el Servicio ofrecido por la plataforma Buki el Usuario podrá agendar una cita con cualquiera de los Establecimientos Comerciales o Freelancers afiliados a la misma los cuales se desempeñan como proveedores independientes y que utilizan la plataforma Buki como medio para ofertar sus servicios.</p>
            <p>El Servicio permitirá, además, que el Usuario pueda agendar una cita de forma personalizada con cualquiera de los estilistas de los distintos Establecimientos Comerciales afilados a la plataforma Buki pudiendo identificarlos en atención a la información que los referidos establecimientos pongan a disposición en la plataforma.</p>
            <p>El Usuario deberá escoger el o los servicios ofrecidos por los Establecimientos Comerciales o Freelancers e ingresar los datos exigidos en el formulario de reserva de citas a través de la plataforma Buki. Luego deberá efectuar el pago correspondiente por el servicio a través de la plataforma Buki para que se pueda emitir la solicitud al Establecimiento Comercial o Freelancer elegido. Finalmente, confirmará la cita en atención a su disponibilidad  con el detalle de la fecha, horario y estilista (de ser el caso) del Establecimiento Comercial.  </p>
            <p>Buki se reserva el derecho de modificar los alcances del Servicio brindado a través de la plataforma lo cual será comunicado al Usuario mediante cualquiera de los datos de contacto proporcionados al momento de su registro como son su correo electrónico o número telefónico. El Usuario tendrá la posibilidad de desvincularse de la plataforma en caso no encontrarse de acuerdo con la modificación a los términos y condiciones realizada por Buki.</p>
        <h5>Condiciones de pago y facturación</h5>
        <p>Los precios de los servicios brindados por los Establecimientos Comerciales o Freelancers afiliados a la plataforma Buki serán fijados por los mismos proveedores sin intervención de Buki.. Sin embargo, Buki cobrará una comisión al Usuario por el uso de la plataforma que se verá reflejada y detallada en el importe final a pagar.</p>
        <p>Para efectuar el pago de los servicios ofrecidos a través de la plataforma, el Usuario deberá ingresar los datos de su tarjeta de crédito o débito en el formulario virtual que para dicho efecto se pone a su disposición. El titular de la tarjeta de crédito o débito es responsable de los datos ingresados durante el proceso de registro a la plataforma o durante la reserva de alguna cita encontrándose obligado al pago frente al emisor de la misma. </p>
        <p>El Usuario conviene desde ya que cualquier reclamo por consumos no reconocidos deberá ser canalizado a través de la entidad bancaria emisora de la tarjeta de crédito o débito de conformidad con lo establecido en la Resolución SBS N° 6523-2013 “Reglamento de tarjetas de crédito y débito” o normas que la modifiquen. </p>
        <p>El Usuario reconoce y acepta que los Establecimientos Comerciales y/o Freelancers  son los proveedores a cargo de la emisión de los comprobantes de pago por los servicios adquiridos a través de la plataforma Buki. En ese sentido, el Usuario acepta que Buki no es el responsable de la emisión de estos documentos en la medida que solo es una plataforma de intermediación que no comercializa ni ofrece servicios y/o productos.  </p>
        <h5>Medios de pago</h5>
        <p>El pago de las órdenes de servicio que hayan sido solicitadas mediante la plataforma Buki se realizará a través del medio de pago seleccionado por el Usuario.  El Usuario es responsable de garantizar la veracidad y exactitud de la información relacionada a los métodos de pago que haya ingresado en la plataforma Buki.  </p>
        <p>El Usuario declara que es el titular de todos los medios de pagos registrados en la plataforma Buki, reconocimiento de manera expresa e inequívoca que dichos medios de pago no pertenecen a terceros. No obstante, en caso de que los medios de pago registrados por el Usuario sean de titularidad de terceros el Usuario declara y reconoce contar la autorización del titular del medio de pago para su empleo en la plataforma Buki.</p>
        <p>El Usuario exime de toda responsabilidad a Buki por el uso no autorizado de los medios de pago utilizados en la plataforma cuya titularidad correspondan a terceros.</p>
        <p>El Usuario acepta que en caso el medio de pago seleccionado haya expirado o vencido, sea rechazado, o no cuente con fondos suficientes para realizar el pago de la orden de servicio, Buki podrá realizar el cobro del total de la orden de servicio mediante cualquier otra tarjeta de crédito o débito registrada o medio de pago disponible que se encuentre vinculado a la cuenta del Usuario en la plataforma. </p>
        <h5>Cancelaciones y devoluciones</h5>
        <p>El Usuario podrá cancelar una orden de servicio sin recargo alguno siempre que la cancelación se realice  con una anticipación mayor a 24 horas de la hora programada para la cita. Para dichos efectos, el Usuario podrá visualizar el estado de su orden de servicio el cual se mostrará luego de la confirmación del pago en la plataforma. </p>
        <p>El Usuario reconoce que las devoluciones que se originen con motivo de la cancelación de una orden de servicio podrían no ser inmediatas y que dependen del tiempo que tomen las entidades bancarias involucradas en el proceso de devolución.</p>
        <p>En caso de que la cancelación de la orden de servicio no se realice dentro del tiempo previsto en el primer párrafo del presente apartado, el Usuario tendrá la obligación de pagar la totalidad del importe correspondiente al valor del servicio del Establecimiento Comercial y/o Freelancer,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      la comisión por el uso de la plataforma de Buki y la comisión por el servicio de la pasarela de pago. En ese sentido, el Usuario no podrá solicitar el reembolso o la devolución de ninguno de los conceptos antes mencionados a la plataforma Buki sin perjuicio de lo señalado en el párrafo siguiente. </p>
        <p>Sin embargo, el Usuario podrá solicitar la devolución del valor del servicio provisto por el Establecimiento Comercial y/o Freelancer siempre que lo haga de manera directa al proveedor en atención a sus propias políticas internas. El Usuario reconoce que en ningún caso la devolución del valor del servicio provisto por el Establecimiento Comercial y/o Freelancer implicará la devolución de la comisión cobrada por la plataforma Buki o por la pasarela de pago</p>
        <h5>Límites de responsabilidad</h5>
        <p>Con la aceptación de los términos y condiciones el Usuario reconoce y acepta que Buki pone a su disposición una plataforma digital de intermediación que le permite ponerse en contacto con los Establecimientos Comercialesy Freelancers afiliados para adquirir los servicios ofrecidos por estos. </p>
        <p>El Usuario reconoce que Buki no interviene de ninguna manera en las relaciones de consumo que celebren con los Establecimientos Comerciales  y Freelancers que usan la plataforma para colocar sus servicios. En consecuencia, el Usuario también reconoce que el Establecimiento Comercial o Freelancer afiliado a la plataforma Buki será el único responsable respecto a la naturaleza y características del servicio contratado, así como de las promociones y ofertas de sus propios servicios.</p>
        <p>Considerando que los Establecimientos Comerciales y Freelancers afiliados a la plataforma Buki ofrecen servicios estéticos, belleza y spa, los Usuarios eximen de responsabilidad a Buki por cualquier error, omisión o defecto en la prestación de estos servicios. </p>
        <p>De esta manera, el Usuario reconoce y acepta que los servicios adquiridos con los Establecimientos Comerciales y/o Freelancers son asumidos bajo su propio riesgo y responsabilidad no pudiendo reclamar a Buki por la falta de idoneidad de estos ni solicitar la indemnización por concepto de daños y/o perjuicios que haya podido sufrir con motivo del servicio contratado. </p>
    <p>De esta manera, sin limitarse a la presente lista, los Usuarios reconocen que Buki no será responsable en ninguno de los siguientes casos:</p> 
    <ol class="decimal">
        <li>Cuando el servicio brindado por el Establecimiento Comercial y/o Freelancer haya comprometido la estética, salud, integridad del Usuario o de la persona para la cual se haya reservado la cita.</li>
        <li>Cuando el Establecimiento Comercial y/o Freelancer no cumpla con la fecha y/o con el horario exacto de la cita agendada.</li>
        <li>Cuando el Establecimiento Comercial no cumpla destinar al estilista seleccionado por el Usuario para la atención de la cita agendada.</li>
        <li>Cuando el Establecimiento Comercial  y/o Freelancer no cumpla con las promociones y ofertas de sus propios servicios ofrecidos en la plataforma.</li>
        <li>Por la calidad e idoneidad de los productos cosméticos  y/o de otro tipo empleados por el Establecimiento Comercial y/o el Freelancer para la atención de sus servicios.</li>
        <li>Por la falta de disponibilidad de los productos y/o recursos necesarios para brindar el servicio agendado.</li>
        <li>Por cualquier tipo de circunstancia relacionada a la atención del personal de los Establecimientos Comerciales y/o Freelancers. </li>
        <li>Por la confidencialidad de la información personal que el Usuario haya entregado de forma directa al Establecimientos Comerciales y/o Freelancer.</li>
        <li>Por el incumplimiento de la regulación administrativa, municipal y/o sectorial que deban observar los Establecimientos Comerciales y/o Freelancers con motivo de la prestación de sus servicios.</li>
        <li>Por el equipamiento de las instalaciones empleados por los Establecimientos Comerciales y/o Freelancers.</li>
        <li>Por la atención de los reclamos y/o quejas relacionadas a los servicios brindados por los Establecimientos Comerciales y/o Freelancers.</li>
    </ol>   
    <p>El Usuario declara que al ser Buki una plataforma que tiene como propósito establecer un contacto entre Usuarios y centros de belleza, solo se encuentra obligada a responder por el servicio de uso de la plataforma mas no por ningún hecho relacionado a los servicios brindados por los Establecimientos Comerciales o Freelancers afiliados. </p>
    <h5>Propiedad Intelectual</h5>
    <p>El Usuario reconoce que todos los derechos de propiedad intelectual e industrial sobre cualquier contenido y elemento incluido en la plataforma Buki, sin limitación alguna, tales como marcas, logotipos, nombres comerciales, lemas comerciales, imágenes, gráficos, diseños, sonidos, bases de datos, audio, video, software, entre otros, son de propiedad de Buki. En ese sentido, el Usuario no podrá usar, modificar reproducir, divulgar ni publicar cualquiera de los elementos de propiedad intelectual e industrial de titularidad de Buki sin su debida autorización.</p>
    <p>La aceptación de los presente términos y condiciones en modo alguno implicará que Buki ha cedido al Usuario derechos sobre los elementos de propiedad intelectual e industrial contenidos en la plataforma digital.</p>
    <h5>Protección de datos personales</h5>
    <p>Buki declara que es titular del banco de datos personales denominado “Usuarios de la plataforma Buki” donde almacenará los datos personales de los Usuarios de la plataforma. Asimismo, informa que ha solicitado el registro del referido banco de datos bajo el número de ingreso N° 1452 ante la Autoridad Nacional de Protección de Datos Personales (ANPDP) del Ministerio de Justicia y de Derechos Humanos.</p>
    <p>De igual manera, Buki informa que los datos personales ingresados por los Usuarios en el Registro serán almacenados y tratados de conformidad con las disposiciones de la Ley N° 29733, Ley de Protección de Datos Personales y su Reglamento aprobado por Decreto Supremo N° 003-2013-JUS, así como por sus normas modificatorias o sustitutorias y las Políticas de Privacidad y Tratamiento de Datos Personales que acepten los Usuarios en el momento de su registro a la plataforma. </p>
    <h5>Libro de reclamaciones</h5>
    <p>Buki pone a disposición del Usuario el libro de reclamaciones para la atención de reclamos y quejas relacionadas al uso de la plataforma en atención a lo dispuesto en la Ley N° 29571, Código de Protección y Defensa del Consumidor. Asimismo, informa a los Usuarios que el plazo de atención de reclamos y quejas es de quince (15) días hábiles de conformidad con lo establecido en el artículo 24° del referido cuerpo normativo.  </p>
    <p>tendrán la alternativa de recibir atención personal para gestionar los mismos, en caso así lo requieran, de conformidad con lo establecido en la Ley Nº 31601, que modifica la Ley N° 29571, Código de Protección y Defensa del Consumidor. </p>
    <h5>Ley aplicable y jurisdicción</h5>
    <p>Los términos y condiciones de la plataforma Buki y la relación entre esta y el Usuario se regirá e interpretará de conformidad con la legislación Peruana vigente</p>
    <p>Última actualización: XXXXXXXX</p>
</div>
    </div>
</div>
<div class="terms-conditions" *ngIf="language==2 || language=='2'">
    <div class="smallcontainer container">
        <div class="termsWrap">
            <h1>TERMS AND CONDITIONS</h1>
            <h5> General Aspects</h5>
            <p>
                Buki Peluplanner S.A.C., identified with R.U.C. No. 20610927379, located at Av. Javier Prado Este No. 2248, San Borja district, province and department of Lima, is a company incorporated under the laws of the Republic of Peru, owner of this digital platform which, for all purposes of these terms and conditions, shall henceforth be referred to as "Buki".
            </p>

            <h5> Definitions </h5>
            <p>
                In the interpretation and application of these terms and conditions, the following definitions shall be considered:
            </p>

            <ol class="dot">
                <li><b>Commercial establishment:</b> beauty center affiliated with the Buki platform for offering beauty, aesthetic, and spa services to Users.
                </li>

                <li>
                    <b>Access data:</b> username and password used for registration and login to the User's account created on the Buki platform.
                </li>

                <li>
                    <b>Virtual forms:</b> digital forms available on the platform for registration by filling in the required personal information.
                </li>

                <li><b>Freelancer: beauty professional or stylist affiliated with the Buki platform who provides beauty and/or aesthetic services independently to Users.</b>
                </li>

                <li><b>Registration:</b> process by which an individual enters their personal information into the virtual form with the purpose of creating an account on the Buki platform. </li>

                <li><b>Service:</b> intermediation work performed by the digital platform to facilitate the consumer relationship between Users and the beauty centers affiliated with the platform.</li>
                <li><b>Service order:</b> request made by the User regarding a service provided by a beauty center or Freelancer after payment has been confirmed.</li>
                <li><b>User:</b> natural person who has registered an account on the Buki platform.</li>
            </ol>

            <h5>Platform Operation</h5>

            <p>The Buki platform is a technological tool designed to facilitate intermediation between Users and Commercial Establishments or Freelancers in order for the former to hire the services offered by the latter. Accordingly, the user acknowledges that at all times Buki acts as a third-party intermediary unrelated to the consumer relationships established between Users and Commercial Establishments or Freelancers.</p>
            <h5>Acceptance of the terms and conditions</h5>
            <p>These terms and conditions govern the intermediation service provided through the Buki platform, and no alternative sources can be claimed by Users except for those obligations to which Buki is subject by legal mandate.</p>
            <p>By registering on the Buki platform voluntarily and expressly, the User accepts the content of these terms and conditions, committing irrevocably to their full compliance. The User declares having read and understood all the conditions and obligations contained in these terms and conditions, expressing their agreement by merely registering and/or using the platform.</p>
            <p>The natural person who accepts these terms and conditions declares and guarantees that they are the owner of the personal information they enter during the registration as a User on the Buki platform. Furthermore, they declare that the personal information provided for their registration is true and assume responsibility for providing inaccurate, erroneous, or fraudulent information. </p>

            <h5>User Registration:</h5>
            <p>Registration to the Buki platform is free and must be done personally by the User of the account. The User can only access the Buki platform through duly authorized means.</p>
            <p>The User must mandatorily register on the Buki platform to access the contents provided by it. For this, they must have a smartphone with iOS or Android operating systems and an internet connection to download the application to their device. Those who have not registered as Users of the platform can view its content but will not be able to use the services offered by the Commercial Establishments or Freelancers without prior registration.</p>
            <p>Also, the User must fill in the virtual registration forms with their personal information and verify that each piece of data provided for registration is correct. Additionally, the User commits to updating the personal data originally provided for registration when they have undergone changes or modifications. </p>
            <p>Once registered, a personal account will be assigned to the User to access the content of the Buki platform and to schedule appointments with the various Commercial Establishments or Freelancers offering services in that space. The account created by the User is personal, unique, and non-transferable, and it is prohibited for the same User to register more than one account.</p>
            <p>Access to the Buki platform will be through the entry of the access data generated for these purposes, consisting of the user and password, which allow validating the identity of the registered User.</p>
            <p>Buki may employ additional security mechanisms such as personal questions, email verification, sending security codes to mobile devices, among others, with the purpose of verifying the User's identity. However, Buki is not responsible if the User provides their access data to third parties to access the Buki platform and/or make use of it.</p>
            <p>Buki reserves the right to suspend, cancel, or disable, temporarily or permanently, a User's registered account on the platform in the following cases:
            </p>
            <ol class="dot">
                <li>When the personal data provided for the registration of an account are not true or accurate.
                </li>
                <li>When there is a duplication of accounts by the same User or coincidences in the personal information corresponding to more than one registered account.</li>
                <li>When there are indications of fraud or identity theft regarding a registered account.</li>
                <li>When the User breaches any of the terms and conditions of use of the platform.</li>
                <li>When the User requests to deactivate their account.</li>
                <li>When there is a mandate from an administrative or judicial authority that requests it.</li>
            </ol>
            <h5>Use of the Buki platform:</h5>
            <p>The User will be responsible for the compatibility of their smartphone with the use of the application containing the Buki platform. In this regard, Buki does not guarantee the proper functioning of the application if the User's mobile device does not meet the technical and internet connection conditions that allow its proper installation and operation.</p>
            <p>The User commits to making appropriate and lawful use of the Buki platform in accordance with the applicable legislation in force, these terms and conditions, morals, and good customs. In general, the User commits to act in good faith in the use of the Buki platform, to comply with these terms and conditions and with the obligations they assume by virtue of their acceptance.</p>
            <p>The User is aware that the Buki platform may have limitations in availability and continuity in its operation due to maintenance circumstances or causes beyond Buki's control. In these cases, Buki will take all measures that reasonably lie within its reach and control to restore the proper functioning of the digital platform.</p>
            <p>Notwithstanding the above, the User acknowledges that Buki will not have responsibility in those cases of breaking the causal link provided in the regulation and legal order in force. </p>
            <h5>User Obligations:</h5>
            <p>Through the acceptance of these terms and conditions, the User voluntarily, expressly, and irrevocably commits to comply with the following obligations:</p>
            <ol class="decimal">
                <li>Not to transfer, resell, or assign their account to third parties. Nor does it grant authorization to third parties to make use of it.</li>
                <li>Not to make use of an account other than their own without due authorization from its owner.</li>
                <li>Not to use the platform for illicit or illegal purposes that may involve the violation of third-party rights.</li>
                <li>To keep the confidentiality of their access data to the Buki platform, such as their username and password, being prohibited from transmitting or transferring them to third parties.</li>
                <li>To keep the personal data registered in their account updated when these have been modified.</li>
                <li>Not to use the Buki platform with an incompatible or unauthorized mobile device.</li>
                <li>Not to access or use other Users' personal data for purposes not intended in the terms and conditions.</li>
                <li>Not to manipulate or tamper with the digital platform by introducing viruses, software, programs, or by any technological or analogous method for illicit or fraudulent purposes that may cause harm and/or affect its normal functioning.
                </li>
            </ol>
            <h5>Service Conditions:</h5>
            <p>Through the Service offered by the Buki platform, the User will be able to schedule an appointment with any of the Commercial Establishments or Freelancers affiliated with it, who act as independent providers and use the Buki platform to offer their services.</p>
            <p>The Service will also allow the User to schedule a personalized appointment with any of the stylists from the various Commercial Establishments affiliated with the Buki platform, being able to identify them according to the information that the aforementioned establishments make available on the platform.</p>
            <p>The User must choose the service or services offered by the Commercial Establishments or Freelancers and enter the required data in the appointment booking form through the Buki platform. Then, they must make the corresponding payment for the service through the Buki platform for the request to be sent to the chosen Commercial Establishment or Freelancer. Finally, the User will confirm the appointment according to its availability with the details of the date, time, and stylist (if applicable) of the Commercial Establishment.</p>
            <p>Buki reserves the right to modify the scope of the Service provided through the platform, which will be communicated to the User through any of the contact data provided at the time of their registration, such as their email or telephone number. The User will have the option to disassociate from the platform if they do not agree with the modification to the terms and conditions made by Buki.</p>
            <h5>Payment and Billing Conditions</h5>
            <p>The prices of the services provided by the Commercial Establishments or Freelancers affiliated with the Buki platform will be set by the same providers without Buki's intervention. However, Buki will charge a commission to the User for the use of the platform, which will be reflected and detailed in the final amount to pay.</p>
            <p>To make the payment for the services offered through the platform, the User must enter their credit or debit card details in the virtual form provided for this purpose. The owner of the credit or debit card is responsible for the data entered during the registration process on the platform or during the booking of an appointment, being obligated to pay in front of the issuer of the same.</p>
            <p>The User agrees from now on that any claim for unrecognized consumption must be channeled through the issuing bank of the credit or debit card in accordance with the provisions of Resolution SBS No. 6523-2013 "Regulation of credit and debit cards" or norms that modify it.</p>
            <p>The User acknowledges and accepts that the Commercial Establishments and/or Freelancers are the providers in charge of issuing the payment receipts for the services acquired through the Buki platform. In this sense, the User accepts that Buki is not responsible for the issuance of these documents to the extent that it is only an intermediation platform that does not market or offer services and/or products. </p>
            <h5>Means of Payment</h5>
            <p>The payment for the service orders requested through the Buki platform will be made through the means of payment selected by the User. The User is responsible for ensuring the veracity and accuracy of the information related to the payment methods entered on the Buki platform.</p>
            <p>The User declares that they are the owner of all payment methods registered on the Buki platform, expressly and unequivocally recognizing that these payment methods do not belong to third parties. However, if the payment methods registered by the User are owned by third parties, the User declares and recognizes that they have the authorization from the owner of the payment method for its use on the Buki platform.</p>
            <p>The User exempts Buki from all responsibility for the unauthorized use of the payment methods used on the platform whose ownership corresponds to third parties.</p>
            <p>The User accepts that if the selected payment method has expired or is due, is rejected, or does not have sufficient funds to make the payment for the service order, Buki may charge the total of the service order through any other credit or debit card registered or payment method available that is linked to the User's account on the platform.</p>
            <h5>Cancellations and Refunds</h5>
            <p>The User may cancel a service order without any charge provided that the cancellation is made with more than 24 hours notice from the scheduled time for the appointment. For these purposes, the User will be able to view the status of their service order which will be shown after the payment confirmation on the platform.</p>
            <p>The User acknowledges that refunds arising from the cancellation of a service order may not be immediate and depend on the time taken by the banking entities involved in the refund process.</p>
            <p>If the cancellation of the service order is not made within the time stipulated in the first paragraph of this section, the User will be obligated to pay the total amount corresponding to the value of the service of the Commercial Establishment and/or Freelancer, the commission for the use of the Buki platform, and the commission for the payment gateway service. In this regard, the User may not request a refund or return of any of the aforementioned concepts from the Buki platform without prejudice to the statement in the following paragraph. </p>
            <p>However, the User may request a refund of the value of the service provided by the Commercial Establishment and/or Freelancer provided that they do so directly to the provider in accordance with their own internal policies. The User acknowledges that in no case will the refund of the value of the service provided by the Commercial Establishment and/or Freelancer imply the refund of the commission charged by the Buki platform or by the payment gateway.</p>
            <h5>Limits of Liability</h5>
            <p>With the acceptance of the terms and conditions, the User acknowledges and accepts that Buki makes available a digital intermediation platform that allows them to get in touch with the Commercial Establishments and Freelancers affiliated to acquire the services offered by them. </p>
            <p>The User acknowledges that Buki does not intervene in any way in the consumer relationships that they establish with the Commercial Establishments and Freelancers who use the platform to place their services. Consequently, the User also acknowledges that the Commercial Establishment or Freelancer affiliated with the Buki platform will be the sole responsible for the nature and characteristics of the contracted service, as well as for the promotions and offers of their own services.</p>
            <p>Considering that the Commercial Establishments and Freelancers affiliated with the Buki platform offer aesthetic, beauty, and spa services, Users exempt Buki from responsibility for any error, omission, or defect in the provision of these services. </p>
            <p>In this way, without being limited to the present list, Users acknowledge that Buki will not be responsible in any of the following cases:</p>
            <ol class="decimal">
                <li>When the service provided by the Commercial Establishment and/or Freelancer has compromised the aesthetics, health, integrity of the User or the person for whom the appointment was reserved.</li>
                <li>When the Commercial Establishment and/or Freelancer does not comply with the date and/or the exact time of the scheduled appointment.</li>
                <li>When the Commercial Establishment does not assign the stylist selected by the User for the attention of the scheduled appointment.</li>
                <li>When the Commercial Establishment and/or Freelancer does not comply with the promotions and offers of their own services offered on the platform.</li>
                <li>For the quality and suitability of the cosmetic products and/or other types used by the Commercial Establishment and/or the Freelancer for the attention of their services.</li>
                <li>For the lack of availability of the products and/or resources necessary to provide the scheduled service.</li>
                <li>For any type of circumstance related to the attention of the staff of the Commercial Establishments and/or Freelancers. </li>
                <li>For the confidentiality of the personal information that the User has directly delivered to the Commercial Establishments and/or Freelancer.</li>
                <li>For non-compliance with the administrative, municipal, and/or sectoral regulation that the Commercial Establishments and/or Freelancers must observe in the provision of their services.</li>
                <li>For the equipment of the facilities used by the Commercial Establishments and/or Freelancers.</li>
                <li>For the attention to complaints and/or claims related to the services provided by the Commercial Establishments and/or Freelancers.</li>
            </ol>  
            <p>The User declares that since Buki is a platform that aims to establish contact between Users and beauty centers, it is only obligated to respond for the service of using the platform but not for any event related to the services provided by the Commercial Establishments or Freelancers affiliated.</p>
            <h5>Intellectual Property</h5>
            <p>The User acknowledges that all intellectual and industrial property rights on any content and element included in the Buki platform, without limitation, such as trademarks, logos, trade names, commercial slogans, images, graphics, designs, sounds, databases, audio, video, software, among others, are owned by Buki. In this regard, the User may not use, modify, reproduce, disclose, or publish any of the intellectual and industrial property elements owned by Buki without their due authorization.</p>
            <p>The acceptance of these terms and conditions in no way implies that Buki has transferred to the User rights over the intellectual and industrial property elements contained in the digital platform.</p>
            <h5>Protection of personal data</h5>
            <p>Buki declares that it is the owner of the personal data bank called “Users of the Buki platform” where it will store the personal data of the Users of the platform. Likewise, it informs that it has requested the registration of the aforementioned data bank under entry number No. 1452 before the National Authority for the Protection of Personal Data (ANPDP) of the Ministry of Justice and Human Rights.</p>
            <p>In the same way, Buki informs that the personal data entered by the Users in the Registration will be stored and processed in accordance with the provisions of Law No. 29733, Law on the Protection of Personal Data and its Regulation approved by Supreme Decree No. 003-2013-JUS, as well as by its amending or substitutive norms and the Privacy and Personal Data Treatment Policies accepted by the Users at the time of their registration on the platform. </p>
            <h5>Book of complaints</h5>
            <p>Buki makes available to the User the book of complaints for the attention of claims and complaints related to the use of the platform in accordance with the provisions of Law No. 29571, Code of Protection and Defense of the Consumer. Likewise, it informs the Users that the response time for complaints and claims is fifteen (15) business days in accordance with the provisions of Article 24 of the aforementioned legal body. </p>
            <p>Users will have the alternative of receiving personal attention to manage the same, if so required, in accordance with the provisions of Law No. 31601, which amends Law No. 29571, Code of Protection and Defense of the Consumer. </p>
            <h5>Applicable law and jurisdiction</h5>
            <p>The terms and conditions of the Buki platform and the relationship between it and the User will be governed and interpreted in accordance with the current Peruvian legislation</p>
            <p>Last update: XXXXXXXX</p>
        </div>
    </div>
</div>